import React from 'react'
import { Fade, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useMutation } from 'react-query'

import { InvestmentRow } from './InvestmentRow'
import { approve, reject } from 'api'

const useApprovalsStyles = makeStyles(() => ({
  tableContainer: {
    minHeight: 610,
  },
}))

export const InvestmentApprovals = ({ investments }) => {
  const classes = useApprovalsStyles()

  const [rejectChanges, { isLoading: isRejectLoading }] = useMutation(reject)
  const [approveChanges, { isLoading: isApproveLoading }] = useMutation(approve)
  const isMutationInProgress = isRejectLoading || isApproveLoading

  return (
    <Fade timeout={1000} in>
      <TableContainer className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Company</TableCell>
              <TableCell>Investor</TableCell>
              <TableCell>Changed by</TableCell>
              <TableCell>Date</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {investments.map(investment => (
              <InvestmentRow
                key={investment.id}
                investment={investment}
                disableActions={isMutationInProgress}
                approveChanges={approveChanges}
                rejectChanges={rejectChanges}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Fade>
  )
}
