import React from 'react'
import { requiredField, currency, isNumeric } from 'validators'
import { FormCurrencyInput, FormTextInput, FormTextArea, FormDateInput } from 'components'
import { MILLISECONDS_IN_DAY } from 'constants/date'

export const AddWarrantsInvestment = ({ onSharesOrPriceChange, formValues }) => (
  <>
    <FormTextInput
      label="Number of shares"
      required
      name="securities.number_of_shares"
      validators={[requiredField, isNumeric]}
      onChange={onSharesOrPriceChange}
    />

    <FormCurrencyInput
      label="Warrant price, £"
      hintText="Price paid by investor for the warrant."
      required
      name="securities.warrant_price"
      validators={[requiredField, currency]}
      onChange={onSharesOrPriceChange}
    />

    {/* prettier-ignore */}
    <FormDateInput
      label="Exercise period start date"
      name="securities.exercise_start"
      maxDate={formValues?.securities?.exercise_end && (formValues?.securities?.exercise_end - MILLISECONDS_IN_DAY)}
      validators={[requiredField]}
      required
    />
    {/* prettier-ignore */}
    <FormDateInput
      label="Exercise period end date"
      name="securities.exercise_end"
      minDate={formValues?.securities?.exercise_start && (formValues?.securities?.exercise_start + MILLISECONDS_IN_DAY)}
      validators={[requiredField]}
      required
    />

    <FormCurrencyInput
      label="Exercise price, £"
      hintText="Price paid by investor to convert the warrant."
      required
      name="securities.exercise_price"
      validators={[requiredField, currency]}
    />

    <FormTextArea label="Additional notes" name="securities.additional_notes" rows={1} />
  </>
)
