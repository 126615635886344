import React, { useEffect } from 'react'
import { useSetState } from 'react-use'
import { useMutation, useQuery, queryCache } from 'react-query'
import { isString, intersection } from 'lodash'
import firebase from 'firebase/app'

import { AuthContext } from './AuthContext'
import { authService } from 'services/AuthService'
import {
  login as loginApi,
  serverLogin as serverLoginApi,
  logout as logoutApi,
  register as registerApi,
  getProfile as getProfileApi,
} from 'api'

const isFBClientEnabled = !!process.env.REACT_APP_ENABLE_FB_CLIENT
const initialState = {
  isAuthenticated: false,
  isAuthenticating: false,
  apiErrorMessage: '',
  permissions: [],
  role: null,
  profile: {},
  loaded: false,
}

if (isFBClientEnabled) {
  firebase.auth().onIdTokenChanged(async user => {
    if (user) {
      const token = await firebase.auth().currentUser.getIdToken(false)
      authService.setToken(token)
    }
  })
}

export const AuthProvider = ({ children }) => {
  const [{ isAuthenticated, profile, role, apiErrorMessage, permissions, loaded }, setState] = useSetState(initialState)

  useEffect(() => {
    const initAuth = async () => {
      const token = authService.getToken()
      if (token) {
        setState({ isAuthenticated: true, profile, role })
      }
    }
    initAuth()

    return () => {
      setState({ refreshTokenDelay: null })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [handleLogin, { isLoading: isAuthenticating }] = useMutation(loginApi)
  const [handleServerLogin] = useMutation(serverLoginApi)

  const [handleLogout] = useMutation(logoutApi)
  const [handleRegister] = useMutation(registerApi)

  useQuery(['profile'], getProfileApi, {
    onSuccess: ({ role, ...profile }) => {
      const userRoleName = role ? role.name : null
      setState({
        isAuthenticated: !!role,
        role: userRoleName,
        profile,
        permissions: [...permissions, userRoleName],
        loaded: true,
      })
    },
  })

  const hasPermission = (permissions, userPermissions = []) => {
    if (!permissions) return false

    const requiredPermissions = isString(permissions) ? [permissions] : permissions
    return intersection(userPermissions, requiredPermissions).length === requiredPermissions.length
  }

  const login = async credentials => {
    try {
      await handleServerLogin(credentials)
    } catch (error) {
      throw error
    }
    try {
      const response = await handleLogin(credentials, {
        onError: err => setState({ apiErrorMessage: err.message }),
      })

      if (!response) {
        return
      }

      if (!isFBClientEnabled) {
        const { token } = response
        authService.setToken(token)

        return response.data
      } else {
        const token = await firebase.auth().currentUser.getIdToken(true)
        authService.setToken(token)

        queryCache.invalidateQueries('profile')
        return { token }
      }
    } catch (error) {
      throw error
    }
  }

  const register = async credentials =>
    await handleRegister(credentials, {
      onError: err => setState({ apiErrorMessage: err.message }),
      throwOnError: true,
    })

  const logout = async () => {
    if (isFBClientEnabled) {
      try {
        await firebase.auth().signOut()
      } catch (error) {
        console.log(error)
      }
    }

    await handleLogout()
    authService.removeToken()
    setState(initialState)
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticating,
        isAuthenticated,
        profile,
        role,
        loaded,
        apiErrorMessage,
        login,
        register,
        logout,
        hasPermission,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
