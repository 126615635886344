import React from 'react'
import { useQuery } from 'react-query'
import { getPortfolioShareholdings } from 'api'
import { CurrencyCell, SortableHeader, Spinner, TablePagination } from 'components'
import {
  Box,
  CardContent,
  Fade,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  Typography,
  Card,
  TableCell,
  TableRow,
  Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles/index'
import classnames from 'classnames'
import { currency, paddedNumber } from 'utils/numberFormat'
import { ReportCharts } from '../ReportCharts'
import { useSorting, useTablePagination } from 'hooks'
import { useParams } from 'react-router-dom'
import { CSVLink } from 'react-csv'

const useStyles = makeStyles(theme => ({
  notableMetrics: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridColumnGap: theme.spacing(2),
  },
  tableContainer: {
    minHeight: 100,
  },
  subCards: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridColumnGap: theme.spacing(2),
    gridRowGap: theme.spacing(2),
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    boxShadow: theme.customShadows.shadow1,
    borderRadius: '10px',
    padding: theme.spacing(4),
    '& .MuiCardContent-root': {
      padding: 0,
    },
  },
  tooltipValue: {
    fontWeight: 'bold',
  },
  exportButton: {
    color: '#fff',
    textDecoration: 'none',
  },
}))

const SORTING_LABELS = {
  COMPANY_NAME: 'company_name',
  GROSS_INVESTED_CAPITAL: 'gross_invested_capital',
  NET_INVESTMENT_COST: 'net_investment_cost',
  SHARES_SOLD: 'shares_sold',
  REALISED_RETURNS: 'invested_capital_received',
  CURRENT_SHAREHOLDING: 'current_shareholding',
  CURRENT_MARKETED_PRICE: 'current_market_price',
  CURRENT_VALUE_OF_HOLDINGS: 'current_value_of_holdings',
  YEARS_SINCE_FIRST_INVESTMENT: 'years_since_first_investment',
  TOTAL_RETURN_GROSS: 'total_return_gross',
  TOTAL_RETURN_NET: 'total_return_net',
}

export const InvestorPortfolioShareholdings = () => {
  const classes = useStyles()
  const [{ page, rowsPerPage }, onPageChange] = useTablePagination()
  let { investorId } = useParams()

  const { data: portfolioShareholdings, isLoading } = useQuery(
    ['companyShareCap', investorId],
    getPortfolioShareholdings
  )

  const [{ direction, field, sort }, changeSorting] = useSorting({
    getAccessor,
    initialField: SORTING_LABELS.COMPANY_NAME,
    items: [],
  })

  if (isLoading) {
    return <Spinner color="secondary" size={40} centered height="500px" />
  }

  const { stats, table } = portfolioShareholdings
  const { investments_list: investmentsList, footer } = table
  const sortedInvestments = sort([...investmentsList], field, direction)

  const cardClasses = classnames([classes.card])
  const subcardClasses = classnames([classes.card, classes.subCard])

  return (
    <>
      <Fade timeout={1000} in>
        <div className={classes.notableMetrics}>
          <Card className={cardClasses}>
            <CardContent>
              <Typography variant="h6">£{paddedNumber(stats.total_gross_invested_capital)}</Typography>
              <Typography variant="body2" color="textSecondary">
                Total investment
              </Typography>
            </CardContent>
          </Card>

          <div className={classes.subCards}>
            <Card className={subcardClasses}>
              <CardContent>
                <Typography variant="h6">£{paddedNumber(stats.total_value_of_holdings)}</Typography>
                <Typography variant="body2" color="textSecondary">
                  Current value of holdings at date
                </Typography>
              </CardContent>
            </Card>
          </div>

          <div className={classes.subCards}>
            <Card className={subcardClasses}>
              <CardContent>
                <Typography variant="h4">£{paddedNumber(stats.total_income_tax_relief)}</Typography>
                <Typography variant="body2" color="textSecondary">
                  Income tax relief available
                </Typography>
              </CardContent>
            </Card>

            <Card className={subcardClasses}>
              <CardContent>
                <Typography variant="h4">£{paddedNumber(stats.total_net_investment_cost)}</Typography>
                <Typography variant="body2" color="textSecondary">
                  Net cost of investment after EIS
                </Typography>
              </CardContent>
            </Card>
          </div>

          <div className={classes.subCards}>
            <Card className={subcardClasses}>
              <CardContent>
                <Typography variant="h4">£{paddedNumber(stats.total_exposure)}</Typography>
                <Typography variant="body2" color="textSecondary">
                  Exposure
                </Typography>
              </CardContent>
            </Card>

            <Card className={subcardClasses}>
              <CardContent>
                <Typography variant="h4">£{paddedNumber(stats.total_realised_unrealised_return)}</Typography>
                <Typography variant="body2" color="textSecondary">
                  Gross value (including exits)
                </Typography>
              </CardContent>
            </Card>
          </div>
        </div>
      </Fade>

      <Fade timeout={1000} in>
        <Box mt={7.5} mb={7.5} width="100%">
          <TableContainer className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />

                  <TableCell>
                    <SortableHeader
                      direction={direction}
                      byField={field}
                      name={SORTING_LABELS.COMPANY_NAME}
                      clickHandler={changeSorting}
                    >
                      Company
                    </SortableHeader>
                  </TableCell>

                  <TableCell>
                    <SortableHeader
                      direction={direction}
                      byField={field}
                      name={SORTING_LABELS.GROSS_INVESTED_CAPITAL}
                      clickHandler={changeSorting}
                    >
                      Gross Invested Capital
                    </SortableHeader>
                  </TableCell>

                  <TableCell>
                    <SortableHeader
                      direction={direction}
                      byField={field}
                      name={SORTING_LABELS.NET_INVESTMENT_COST}
                      clickHandler={changeSorting}
                    >
                      Net Investment Cost
                    </SortableHeader>
                  </TableCell>

                  <TableCell>
                    <SortableHeader
                      direction={direction}
                      byField={field}
                      name={SORTING_LABELS.SHARES_SOLD}
                      clickHandler={changeSorting}
                    >
                      Shares Sold
                    </SortableHeader>
                  </TableCell>

                  <TableCell>
                    <SortableHeader
                      direction={direction}
                      byField={field}
                      name={SORTING_LABELS.REALISED_RETURNS}
                      clickHandler={changeSorting}
                    >
                      Realised returns
                    </SortableHeader>
                  </TableCell>

                  <TableCell>
                    <SortableHeader
                      direction={direction}
                      byField={field}
                      name={SORTING_LABELS.CURRENT_SHAREHOLDING}
                      clickHandler={changeSorting}
                    >
                      Current Shareholding
                    </SortableHeader>
                  </TableCell>

                  <TableCell>
                    <SortableHeader
                      direction={direction}
                      byField={field}
                      name={SORTING_LABELS.CURRENT_MARKETED_PRICE}
                      clickHandler={changeSorting}
                    >
                      Current Marketed Price
                    </SortableHeader>
                  </TableCell>

                  <TableCell>
                    <SortableHeader
                      direction={direction}
                      byField={field}
                      name={SORTING_LABELS.CURRENT_VALUE_OF_HOLDINGS}
                      clickHandler={changeSorting}
                    >
                      Current Value of Holding
                    </SortableHeader>
                  </TableCell>

                  <TableCell>
                    <SortableHeader
                      direction={direction}
                      byField={field}
                      name={SORTING_LABELS.YEARS_SINCE_FIRST_INVESTMENT}
                      clickHandler={changeSorting}
                    >
                      Years Since Investment
                    </SortableHeader>
                  </TableCell>

                  <TableCell>
                    <SortableHeader
                      direction={direction}
                      byField={field}
                      name={SORTING_LABELS.TOTAL_RETURN_GROSS}
                      clickHandler={changeSorting}
                    >
                      Total Return Multiple: Gross
                    </SortableHeader>
                  </TableCell>

                  <TableCell>
                    <SortableHeader
                      direction={direction}
                      byField={field}
                      name={SORTING_LABELS.TOTAL_RETURN_NET}
                      clickHandler={changeSorting}
                    >
                      Total Return Multiple: Net
                    </SortableHeader>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedInvestments?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(company => (
                  <TableRow key={company.company_name}>
                    <TableCell />
                    <TableCell>{company.company_name}</TableCell>

                    <TableCell>
                      <CurrencyCell value={company.gross_invested_capital} />
                    </TableCell>

                    <TableCell>
                      <CurrencyCell value={company.net_investment_cost} />
                    </TableCell>

                    <TableCell>{paddedNumber(company.shares_sold)}</TableCell>

                    <TableCell>
                      <CurrencyCell value={company.invested_capital_received} />
                    </TableCell>

                    <TableCell>{paddedNumber(company.current_shareholding)}</TableCell>

                    <TableCell>
                      <CurrencyCell value={company.current_market_price} asDecimal />
                    </TableCell>

                    <TableCell>
                      <CurrencyCell value={company.current_value_of_holdings} />
                    </TableCell>

                    <TableCell>{company.years_since_first_investment}</TableCell>

                    <TableCell>{paddedNumber(company.total_return_gross)}</TableCell>

                    <TableCell>{paddedNumber(company.total_return_net)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell />
                  <TableCell>Total</TableCell>
                  <TableCell>
                    <CurrencyCell value={footer.total_gross_invested_capital} />
                  </TableCell>
                  <TableCell>
                    <CurrencyCell value={footer.total_net_investment_cost} />
                  </TableCell>
                  <TableCell>{paddedNumber(footer.total_shares_sold)}</TableCell>
                  <TableCell>
                    <CurrencyCell value={footer.total_invested_capital_received} />
                  </TableCell>
                  <TableCell>{paddedNumber(footer.total_share_holdings)}</TableCell>
                  <TableCell />
                  <TableCell>
                    <CurrencyCell value={footer.total_value_of_holdings} />
                  </TableCell>
                  <TableCell />
                  <TableCell>{paddedNumber(footer.average_total_return_gross)}</TableCell>
                  <TableCell>{paddedNumber(footer.average_total_return_net)}</TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>

          <TablePagination
            count={investmentsList?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            rowsPerPageOptions={[rowsPerPage]}
            onChangePage={onPageChange}
          />
          <Button variant="contained" color="primary">
            <CSVLink data={getExportData(investmentsList, footer)}>Export table to CSV</CSVLink>
          </Button>
        </Box>
      </Fade>

      <Fade timeout={1000} in>
        <ReportCharts data={investmentsList} />
      </Fade>
    </>
  )
}

const getExportData = (table, footer) => {
  const header = [
    'Company',
    'Gross Invested Capital',
    'Net Investment Cost',
    'Shares Sold',
    'Realised returns',
    'Current Shareholding',
    'Current Marketed Price',
    'Current Value of Holding',
    'Years Since Investment',
    'Total Return Multiple: Gross',
    'Total Return Multiple: Net',
  ]

  const exportValues = table.map(company => [
    company.company_name,
    `£${paddedNumber(company.gross_invested_capital)}`,
    `£${paddedNumber(company.net_investment_cost)}`,
    company.shares_sold,
    company.invested_capital_received,
    company.current_shareholding,
    `£${currency(company.current_market_price)}`,
    `£${paddedNumber(company.current_value_of_holdings)}`,
    company.years_since_first_investment,
    company.total_return_gross,
    company.total_return_net,
  ])

  const footerList = [
    [],
    [
      'Total',
      `£${paddedNumber(footer.total_gross_invested_capital)}`,
      `£${paddedNumber(footer.total_net_investment_cost)}`,
      footer.total_shares_sold,
      footer.total_invested_capital_received,
      footer.total_share_holdings,
      '',
      `£${paddedNumber(footer.total_value_of_holdings)}`,
      '',
      footer.average_total_return_gross,
      footer.average_total_return_net,
    ],
  ]

  return [header, ...exportValues, ...footerList]
}

function getAccessor(field) {
  return company => company[field]
}
